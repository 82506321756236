import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { ConfirmationService } from 'app/services/confirmation/confirmation.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    private _userRoles: WritableSignal<string[]> = signal([]);
    public get userRoles(): Signal<string[]> {
        return this._userRoles.asReadonly();
    }
    public set userRoles(value: string[]) {
        this._userRoles.set(value);
        this.rolesLoaded$.next(true);
        this.rolesLoaded$.complete();
    }

    rolesLoaded$: Subject<boolean> = new Subject();

    private _userType: WritableSignal<'CORPORATE' | 'SUBSCRIPTION'> = signal(null);

    public get userType(): Signal<'CORPORATE' | 'SUBSCRIPTION'> {
        return this._userType.asReadonly();
    }
    public set userType(value: 'CORPORATE' | 'SUBSCRIPTION') {
        this._userType.set(value);
    }

    constructor(private dialogService: ConfirmationService) {
        // Load roles from user (e.g., from an API or local storage)
        // this.userRoles = this.loadUserRoles();
    }

    private loadUserRoles(): string[] {
        // Fetch roles from wherever they are stored (backend, token, etc.)
        return ['ADM', 'CON', 'USE_STD', 'USE_FIN', 'USE_TEC']; // Example roles
    }

    hasRole(role: string): boolean {
        return this.userRoles().includes(role);
    }

    hasAnyRole(roles: string[]): boolean {
        return roles.some(role => this.hasRole(role));
    }

    supportedPlan(minimumPlan: 'CORPORATE' | 'SUBSCRIPTION'): boolean {
        if (minimumPlan === 'CORPORATE') {
            return this.userType() === 'CORPORATE';
        }

        return true;
    }

    openInsufficientPermissionsDialog(): void {
        this.dialogService.open({
            title: 'insufficientPermissionTitle',
            message: 'insufficientPermissionDescription',
            dismissible: true,
            actions: {
                confirm: {
                    show: false,
                },
            },
        });
    }
}
